import React from "react";
import Savvy from "./SavvyWarehouseImage.webp";
import Amazon from "./Amazon.webp";
import Poppins from "./Poppins.webp";
import Expedition from "./Expedition.webp";

export default function CaseStudy_Component() {
  const caseStudies = [
    {
      img: Amazon,
      alt: "Amazon Energy Co Image showing a screenshot of the completed application",
      title: "Energy Maintenance engineer issue tracker",
      description:
        "Energy Co, supported by Amazon, needed a way for their on-site maintenance workers to track issues and deliveries. Origin created a full workflow tool.",
      credentials: { username: "Admin", password: "Admin2024!" },
      link: "https://buildsight-maintain-pro-14097ebe.theorigin.ai/",
    },
    {
      img: Savvy,
      alt: "Crevel Europe image showing a screenshot of the completed application",
      title: "Scrap Inventory Mgmt",
      description:
        "Crevel Europe GmbH is one of Europe's fastest growing food wholesalers. Origin's automated, AI architect built and deployed a new scrap inventory process for Crevel, including integration with their ERP, without a single developer touching the code.",
      credentials: { username: "Demo", password: "Demo2024!" },
      link: "https://dummy-savvy-warehouse-319dc556.theorigin.ai/",
    },
    {
      img: Poppins,
      alt: "Poppins Image showing a screenshot of the completed application",
      title: "Poppins team scheduling tool",
      description:
        "Leading London digital agency Poppins wanted a time tracking tool to track time against projects—something they spent significant annual money on from an existing SaaS provider.",
      credentials: { username: "Admin", password: "Admin2024!" },
      link: "https://float-master-orchestra-7eae7425.theorigin.ai/",
    },
    {
      img: Expedition,
      alt: "Expeditionary Management image showing a screenshot of the completed application",
      title: "US Visa workflow",
      description:
        "Award-winning tour production company Expeditionary Management runs global stadium tours for headline artists. They needed a workflow tool that could track visa applications for the hundreds of crew, including a visa letter generation feature.",
      credentials: { username: "Admin", password: "Admin2024!" },
      link: "https://visa-voyager-c9bfa6ad.theorigin.ai/",
    },
  ];

  return (
    <div>
      {/* Background image */}
      <div className="w-full bg-transparent relative">
        {/* Centered heading */}
        <h2 className="text-center pl-2 pr-2 text-[60px] not-italic font-normal leading-[72px] tracking-[1.2px] text-4xl font-bold tracking-tight text-white sm:text-6xl">
          Created with Origin.
        </h2>

        {/* Case Study Cards */}
        {caseStudies.map((study, index) => (
          <div
            key={index}
            className="w-full max-w-4xl mx-auto lg:max-w-2/3 mt-10"
          >
            <div className="flex flex-col lg:flex-row gap-x-6 p-6 rounded-lg shadow-lg">
              {/* Image Section */}
              <div className="w-full lg:w-2/3 rounded-lg overflow-hidden shadow-md mb-4 lg:mb-0">
                <img
                  className="w-full h-auto object-contain"
                  src={study.img}
                  alt={study.alt}
                />
              </div>

              {/* Text Section */}
              <div className="w-full lg:w-1/3 p-4 rounded-lg shadow-md flex flex-col justify-between">
                <div className="text-white font-aeonik text-14px font-normal leading-160">
                  {study.title}
                </div>
                <div className="mt-5 text-white font-aeonik text-14px font-normal leading-160 opacity-75">
                  <p className="mb-4">{study.description}</p>
                </div>
                <div className="mt-5 text-white font-aeonik text-14px font-normal leading-160">
                  <strong>
                    Use the following credentials to access the dummy site:
                  </strong>
                  <ul className="list-disc ml-4 mt-2">
                    <li>Username: {study.credentials.username}</li>
                    <li>Password: {study.credentials.password}</li>
                  </ul>
                </div>
                <div className="mt-4">
                  <a
                    href={study.link}
                    className="w-30 mt-5 flex justify-center rounded-md border border-transparent bg-[#363638] py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    View demo site
                  </a>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
