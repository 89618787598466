// ["Footer", "Component"]
// react imports go here
import React from "react";
import { Link } from "react-router-dom";

// headless UI imports can go here

// react-redux imports go here

// Importing LogoImage
import LogoImage from "./LogoImage_Image.svg";
import LinkedInImage from "./LinkedIn.png";
import TwitterImage from "./Twitter.png";
import EmailImage from "./Email.png";

// Default export of Footer_Component
export default function Footer_Component() {
  // IMPORTANT: This component serves as a placeholder and does not interact with the context state or functionality.
  return (
    <div className="w-full bg-[#363638] flex items-center p-4 relative rounded-tl-[40px] rounded-tr-[40px] justify-between">
      {/* Replace the plain "Legal" div with a Link component */}
      <div>
        <Link to="/legal" className="text-white hover:underline">
          Legal
        </Link>
      </div>
      <div>
        <img
          src={LogoImage}
          alt="A modest logo featuring a circular dial displayed on a footer"
          className="w-full h-full object-contain"
        />
      </div>
      <div className="flex space-x-4">
        <a
          href="mailto:info@theorigin.ai"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={EmailImage}
            alt="Email"
            className="w-6 h-6 object-contain"
          />
        </a>
        <a
          href="https://x.com/the_origin_ai"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={TwitterImage}
            alt="Twitter"
            className="w-6 h-6 object-contain"
          />
        </a>
        <a
          href="https://linkedin.com/company/theoriginai"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={LinkedInImage}
            alt="LinkedIn"
            className="w-6 h-6 object-contain"
          />
        </a>
      </div>
    </div>
  );
}
