// ["NavigationBar", "Component"]
import { Disclosure } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom"; // Link imported so we can click on links without refreshing the page
import LogoImage from "./LogoImage_Image.svg"; // Importing the LogoImage
import TransparentButton from "./TransparentButton_Component.jsx"; // Importing the TransparentButton

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Example() {
  return (
    <Disclosure as="nav" className="bg-transparent mt-4">
      {({ open }) => (
        <>
          <div className="mx-auto px-2 sm:px-6 lg:px-8">
            <div className="relative flex h-16 items-center justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex flex-1 items-center justify-between sm:items-stretch">
                {/* Left section: Logo */}
                <div className="flex flex-shrink-0 items-center">
                  <Link to="/">
                    <img
                      src={LogoImage}
                      alt="A modest logo featuring a circular dial"
                    />
                  </Link>
                </div>

                {/* Center section: Navigation links */}
                <div className="hidden sm:ml-6 sm:block">
                  <div className="flex space-x-4">
                    <Link
                      to="/"
                      className="text-white hover:text-gray-300 px-3 py-2 text-sm font-medium"
                    >
                      Home
                    </Link>
                    <a
                      href="#case-study"
                      className="text-white hover:text-gray-300 px-3 py-2 text-sm font-medium"
                    >
                      Showcase
                    </a>

                    <Link
                      to="/pricing"
                      className="text-white hover:text-gray-300 px-3 py-2 text-sm font-medium"
                    >
                      Pricing
                    </Link>
                    <a
                      href="mailto:info@theorigin.ai"
                      className="text-white hover:text-gray-300 px-3 py-2 text-sm font-medium"
                    >
                      Contact
                    </a>
                  </div>
                </div>

                {/* Right section: Transparent button */}
                <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                  <TransparentButton />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </Disclosure>
  );
}
