import React from "react";
import { Disclosure } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Link, useNavigate, useLocation } from "react-router-dom";
import LogoImage from "./LogoImage_Image.svg";
import TransparentButton from "./TransparentButton_Component.jsx";

export default function Example() {
  const navigate = useNavigate();
  const location = useLocation();

  // Your original function for DESKTOP "Showcase" usage
  // (left as-is, called only on desktop button below)
  const handleNavigation = (path, anchorId, closeMenu) => {
    if (location.pathname === path) {
      const anchor = document.querySelector(anchorId);
      if (anchor) anchor.scrollIntoView({ behavior: "smooth" });
    } else {
      navigate(path);
      setTimeout(() => {
        const anchor = document.querySelector(anchorId);
        if (anchor) anchor.scrollIntoView({ behavior: "smooth" });
      }, 100);
    }
    // We do NOT call closeMenu() here because for desktop usage, there's no mobile menu to close.
  };

  return (
    <Disclosure as="nav" className="bg-transparent mt-4">
      {({ open }) => (
        <>
          <div className="mx-auto px-4 sm:px-6 lg:px-8">
            <div className="relative flex h-16 items-center justify-between">
              {/* Left section: Mobile menu button and Logo */}
              <div className="flex items-center space-x-4">
                {/* Mobile menu button */}
                <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white sm:hidden">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>

                {/* Logo */}
                <Link to="/">
                  <img
                    src={LogoImage}
                    alt="Origin's circular logo"
                    className="h-8 w-auto sm:h-10"
                  />
                </Link>
              </div>

              {/* Center section: Navigation links (desktop only) */}
              <div className="hidden sm:ml-6 sm:block">
                <div className="flex space-x-4">
                  <Link
                    to="/"
                    className="text-white hover:text-gray-300 px-3 py-2 text-sm font-medium"
                  >
                    Home
                  </Link>
                  {/* DESKTOP "Showcase" uses your handleNavigation logic */}
                  <button
                    onClick={() =>
                      handleNavigation("/", "#case-study", () => {})
                    }
                    className="text-white hover:text-gray-300 px-3 py-2 text-sm font-medium"
                  >
                    Showcase
                  </button>
                  <Link
                    to="/pricing"
                    className="text-white hover:text-gray-300 px-3 py-2 text-sm font-medium"
                  >
                    Pricing
                  </Link>
                  <a
                    href="mailto:info@theorigin.ai"
                    className="text-white hover:text-gray-300 px-3 py-2 text-sm font-medium"
                  >
                    Contact
                  </a>
                </div>
              </div>

              {/* Right section: Transparent button */}
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                <TransparentButton />
              </div>
            </div>
          </div>

          {/* Mobile menu panel */}
          <Disclosure.Panel
            className={`fixed inset-y-0 left-0 z-40 w-64 bg-gray-800 transform ${
              open ? "translate-x-0" : "-translate-x-full"
            } transition-transform duration-300 ease-in-out sm:hidden`}
          >
            <div className="h-full flex flex-col px-4 py-6">
              {/* Close button at the top */}
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-white text-lg font-semibold">Menu</h2>
                <Disclosure.Button className="p-2 text-gray-400 hover:text-white">
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </Disclosure.Button>
              </div>

              {/* Navigation Links for MOBILE */}
              <nav className="space-y-4">
                {/* 
                  1) Make each link a Disclosure.Button
                  2) Use the 'as' prop to turn it into <Link> or <a>
                  3) No extra onClick is required for toggling: 
                     Disclosure.Button will *automatically close* the panel 
                     when clicked. 
                */}
                <Disclosure.Button
                  as={Link}
                  to="/"
                  className="block text-white hover:text-gray-300 px-3 py-2 text-base font-medium"
                >
                  Home
                </Disclosure.Button>

                <Disclosure.Button
                  as="a"
                  href="/#case-study"
                  className="block text-white hover:text-gray-300 px-3 py-2 text-base font-medium"
                >
                  Showcase
                </Disclosure.Button>

                <Disclosure.Button
                  as={Link}
                  to="/pricing"
                  className="block text-white hover:text-gray-300 px-3 py-2 text-base font-medium"
                >
                  Pricing
                </Disclosure.Button>

                <Disclosure.Button
                  as="a"
                  href="mailto:info@theorigin.ai"
                  className="block text-white hover:text-gray-300 px-3 py-2 text-base font-medium"
                >
                  Contact
                </Disclosure.Button>
              </nav>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
