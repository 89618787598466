import React, { useEffect } from "react";

export default function RedirectPage_Component() {
  useEffect(() => {
    // Redirect to the target URL after component mounts
    const targetUrl = "https://www.producthunt.com/posts/origin-6"; // Replace with your destination URL
    window.location.href = targetUrl;
  }, []);

  return (
    <div className="flex items-center justify-center h-screen text-white">
      <h1>Redirecting...</h1>
    </div>
  );
}
