// ["SignUpForm", "Component"]
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setEmail,
  setDescription,
  submitSignUpForm,
} from "./SignUpFormState_Store.jsx";
import BackgroundImageParticles from "./BackgroundImageParticles_Image.webp"; // Import background image

// SignUpForm component initializes and connects to SignUpFormState slice
export default function SignUpForm_Component() {
  const dispatch = useDispatch();
  const { email, description, errorMessages, submissionStatus } = useSelector(
    (state) => state.SignUpFormState
  );

  // Local state for form fields
  const [localEmail, setLocalEmail] = useState(email);
  const [localDescription, setLocalDescription] = useState(description);

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      submitSignUpForm({ email: localEmail, description: localDescription })
    );
  };

  // Handle email change
  const handleEmailChange = (e) => {
    setLocalEmail(e.target.value);
    dispatch(setEmail(e.target.value));
  };

  // Handle description change
  const handleDescriptionChange = (e) => {
    setLocalDescription(e.target.value);
    dispatch(setDescription(e.target.value));
  };

  return (
    <div className="relative w-full h-full min-h-[60vh] flex items-center justify-center">
      {/* Background image */}
      <img
        src={BackgroundImageParticles}
        alt="Light cloud of particles on a black background"
        className="absolute inset-0 w-full h-full object-cover"
      />
      <div className="relative bg-transparent w-3/4 mx-auto py-2 shadow sm:rounded-lg">
        <div className="sm:w-1/2 xs:w-full">
          <h2 className="text-left text-3xl font-extrabold text-white">
            Start creating now
          </h2>
          <p className="mt-2 text-md text-gray-300">
            Origin is now in public beta, so you can jump straight in.
          </p>
          <p className="mt-2 text-md text-gray-300">
            We highly reccomend checking out our{" "}
            <a
              className="text-blue-500 underline hover:text-blue-700"
              href="https://notion.theorigin.ai/Getting-started-076ae5b820454bceaa5bc0d02ebcb957?pvs=4"
            >
              getting started guide
            </a>{" "}
            before jumping in.
          </p>
          <div>
            <p className="mt-5">
              <button
                type="submit"
                onClick={() => window.open("https://alpha.theorigin.ai")}
                className="w-30 flex justify-center rounded-md border border-transparent bg-[#363638] py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                aria-label="Jump to signup see it in action"
              >
                Start building now
              </button>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
