// ["LimitlessPossibilitiesSection", "Component"]

// react imports go here
import React from "react";

// Default export of LimitlessPossibilitiesSection_Component
export default function Pricing_Component() {
  const renderFeature = (value, text) => (
    <li className="flex justify-between">
      <span>
        {text}:{" "}
        <span
          className={`font-bold ${
            typeof value === "boolean"
              ? value
                ? "text-green-400"
                : "text-red-500"
              : "text-gray-100"
          }`}
        >
          {typeof value === "boolean" ? (
            value ? (
              "✓"
            ) : (
              "✕"
            )
          ) : typeof value === "object" ? (
            <>
              <span className="line-through text-red-500">
                {value.original}
              </span>{" "}
              <span className="text-green-400">{value.discounted}</span>
            </>
          ) : (
            value
          )}
        </span>
      </span>
    </li>
  );

  // IMPORTANT: This component serves as a placeholder and does not interact with the context state or functionality.
  return (
    <div className="w-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-10">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {/* Plan 1: Launch */}
        <div className="relative rounded-lg shadow-lg border p-6 bg-gray-900 border-gray-700">
          <h3 className="text-xl font-semibold text-center text-gray-100">
            Beta Launch Plan
          </h3>
          <div className="mt-4 flex justify-center items-baseline text-center">
            <span className="text-4xl font-bold text-gray-100">$25</span>
            <span className="ml-1 text-sm font-medium text-gray-400">
              /month
            </span>
          </div>
          <p className="mt-2 text-sm text-center text-gray-400">
            (plan expires at the end of the launch period)
          </p>
          <div className="my-4 border-t border-gray-700"></div>

          {/* Feature Sections */}
          <h4 className="text-lg font-semibold text-gray-100">Generation</h4>
          <ul className="space-y-2 text-gray-300 text-sm mb-6">
            {renderFeature(
              { original: "$5.00", discounted: "$0" },
              "Token cost per M"
            )}
            {renderFeature(true, "Build and deploy in Origin's cloud")}
            {renderFeature(false, "Build and deploy in own cloud")}
            {renderFeature(
              { original: "20", discounted: "Unlimited" },
              "Number of solutions"
            )}
          </ul>

          <h4 className="text-lg font-semibold text-gray-100">Hosting</h4>
          <ul className="space-y-2 text-gray-300 text-sm mb-6">
            {renderFeature(
              { original: "$15.99 / month", discounted: "$0" },
              "Hosting cost"
            )}
          </ul>

          <h4 className="text-lg font-semibold text-gray-100">
            Team & Support
          </h4>
          <ul className="space-y-2 text-gray-300 text-sm mb-6">
            {renderFeature("1", "Team members")}
            {renderFeature(false, "Dedicated support")}
          </ul>

          <button
            onClick={() => window.open("https://alpha.theorigin.ai")}
            className="mt-6 w-full py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
          >
            Get started
          </button>
        </div>

        {/* Plan 2: Professional */}
        <div className="relative rounded-lg shadow-lg border p-6 bg-gray-900 border-gray-700">
          <h3 className="text-xl font-semibold text-center text-gray-100">
            Professional Plan
          </h3>
          <div className="mt-4 flex justify-center items-baseline text-center">
            <span className="text-4xl font-bold text-gray-100">$500</span>
            <span className="ml-1 text-sm font-medium text-gray-400">
              /month
            </span>
          </div>
          <p className="mt-2 text-sm text-center text-gray-400">
            Our standard plan
          </p>
          <div className="my-4 border-t border-gray-700"></div>

          <h4 className="text-lg font-semibold text-gray-100">Generation</h4>
          <ul className="space-y-2 text-gray-300 text-sm mb-6">
            {renderFeature("$5.00", "Token cost per M")}
            {renderFeature(true, "Build and deploy in Origin's cloud")}
            {renderFeature(true, "Build and deploy in own cloud")}
            {renderFeature("20", "Number of solutions")}
          </ul>

          <h4 className="text-lg font-semibold text-gray-100">Hosting</h4>
          <ul className="space-y-2 text-gray-300 text-sm mb-6">
            {renderFeature("$15.99 / month", "Hosting cost")}
          </ul>

          <h4 className="text-lg font-semibold text-gray-100">
            Team & Support
          </h4>
          <ul className="space-y-2 text-gray-300 text-sm mb-6">
            {renderFeature("5", "Team members")}
            {renderFeature(false, "Dedicated support")}
          </ul>

          <button
            className="mt-6 w-full py-3 bg-gray-700 text-white rounded-lg cursor-not-allowed"
            disabled
          >
            Coming Soon
          </button>
        </div>

        {/* Plan 3: Enterprise */}
        <div className="relative rounded-lg shadow-lg border p-6 bg-gray-900 border-gray-700">
          <h3 className="text-xl font-semibold text-center text-gray-100">
            Enterprise Plan
          </h3>
          <div className="mt-4 flex justify-center items-baseline text-center">
            <span className="text-4xl font-bold text-gray-100">Call Us</span>
          </div>
          <p className="mt-2 text-sm text-center text-gray-400">
            Tailored solutions for large organizations.
          </p>
          <div className="my-4 border-t border-gray-700"></div>

          <h4 className="text-lg font-semibold text-gray-100">Generation</h4>
          <ul className="space-y-2 text-gray-300 text-sm mb-6">
            {renderFeature("Custom", "Token pricing")}
            {renderFeature(true, "Build and deploy in Origin's cloud")}
            {renderFeature(true, "Build and deploy in own cloud")}
            {renderFeature("Unlimited", "Number of solutions")}
          </ul>

          <h4 className="text-lg font-semibold text-gray-100">Hosting</h4>
          <ul className="space-y-2 text-gray-300 text-sm mb-6">
            {renderFeature("Custom", "Hosting cost")}
          </ul>

          <h4 className="text-lg font-semibold text-gray-100">
            Team & Support
          </h4>
          <ul className="space-y-2 text-gray-300 text-sm mb-6">
            {renderFeature("Unlimited", "Team members")}
            {renderFeature(true, "Dedicated support")}
          </ul>

          <button
            className="mt-6 w-full py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
            onClick={() => (window.location.href = "mailto:info@theorigin.ai")}
          >
            Contact Us
          </button>
        </div>
      </div>
    </div>
  );
}
