// ["LimitlessPossibilitiesSection", "Component"]

// react imports go here
import React from "react";

// Import BackgroundImageNeonVectors
import BackgroundImageNeonVectors from "./BackgroundImageNeonVectors_Image.webp";

// Default export of LimitlessPossibilitiesSection_Component
export default function LimitlessPossibilitiesSection_Component() {
  // IMPORTANT: This component serves as a placeholder and does not interact with the context state or functionality.
  return (
    <div className="text-4xl font-bold tracking-tight text-white sm:text-6xl text-center">
      {/* Background image */}
      <div className="w-full text-center bg-transparent relative pt-32 pb-32">
        <div
          className="absolute inset-0 w-full h-full z-0 sm:h-[90vh] xs:h-[200vh]"
          style={{
            backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 0) 80%, rgba(0, 0, 0, 0.8) 100%), url(${BackgroundImageNeonVectors})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            opacity: 0.5,
          }}
        />

        {/* Centered heading with transparent background */}
        <div className="flex flex-col items-center justify-center text-white z-10 relative">
          <h2 className="text-[60px] font-normal tracking-[1.2px] font-bold text-white leading-[1.2] sm:text-[60px] xs:text-[50px] sm:leading-[1.4] xs:leading-[1.3]">
            Limitless Possibilities
          </h2>

          <p className="text-[30px] tracking-normal font-normal text-white my-4">
            (minus the infosec headaches)
          </p>

          {/* New subtext under 'Limitless Possibilities' heading */}
          <div className="w-[90%] sm:w-1/2 xs:w-[90%] mx-auto">
            <p className="text-[20px] tracking-normal font-light text-white my-4">
              You know the tools and software you want to build - but developer
              time is expensive and hard to come by.
            </p>
            <p className="text-[20px] tracking-normal font-light text-white my-4">
              Origin empowers you to build and deploy web-based software without
              the need for a development team.
            </p>
            <p className="text-[20px] tracking-normal font-light text-white my-4">
              Better yet, there are no infosec headaches. Origin is cleverly
              built to generate and deploy within your own cloud environment
              when needed.
            </p>

            <button
              type="submit"
              onClick={() => window.open("https://alpha.theorigin.ai")}
              className="w-30 my-10 mx-auto flex justify-center rounded-md border border-transparent bg-[#363638] py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              aria-label="Jump to signup see it in action"
            >
              Try it out now
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
