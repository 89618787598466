import React from "react";

export default function SeeInActionVideo_Component() {
  return (
    <div className="relative my-8 w-3/4 mx-auto p-8 rounded-[32px] bg-[#1D1D1F] overflow-hidden">
      {/* Gradient overlay */}
      <div className="bg-gradient-to-br from-white to-transparent absolute inset-0 opacity-10" />

      {/* Content wrapper */}
      <div className="relative w-full rounded-[32px] flex flex-col sm:flex-row items-center text-white gap-8">
        {/* Text column (slightly wider than 1/3) */}
        <div className="w-full sm:w-2/5">
          <h1 className="text-white font-aeonik text-[25px] font-normal leading-[120%] tracking-[1.2px]">
            Origin is an entire product team
          </h1>
          <p className="mt-5 text-white font-aeonik text-[14px] font-normal leading-[160%] opacity-50">
            Origin fields you a a project manager, architect, designer,
            developer, QA engineer and devops engineer. It uses this team of AI
            agents to take your non-technical prompts and transform them into
            deployed, production software.
          </p>
          <p className="mt-5">
            <button
              type="submit"
              onClick={() => window.open("https://alpha.theorigin.ai")}
              className="w-30 flex justify-center rounded-md border border-transparent bg-[#363638] py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              aria-label="Jump to signup see it in action"
            >
              Try it out now
            </button>
          </p>
        </div>

        {/* <iframe width="560" height="315" src="https://www.youtube.com/embed/Wrq_GX7Uk6s?si=rnPbQ7O_0ifxWhAk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
        {/* Video column (3/5) */}
        <div className="w-full sm:w-3/5" id="demo">
          <div className="w-full h-full rounded-[32px] flex items-center justify-center">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/8BRATJeHf6c?si=088PxolxIKc7kP2G"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
}
