// HomePageContent_Component.jsx

import React from "react";

// Import components that make up your home page content
import HeadingSection_Component from "./HeadingSection_Component.jsx";
import LimitlessPossibilitiesSection_Component from "./LimitlessPossibilitiesSection_Component.jsx";
import CaseStudy_Component from "./CaseStudy_Component.jsx";
import SignUpForm_Component from "./SignUpForm_Component.jsx";
import CaseStudyVideo_Component from "./CaseStudyVideo_Component.jsx";
import SeeInActionVideo_Component from "./SeeInActionVideo_Component.jsx";

export default function HomePageContent_Component() {
  return (
    <>
      {/* Render HeadingSection_Component */}
      <HeadingSection_Component />

      {/* Render YouTubeVideoSection_Component */}
      <section className="relative -mt-10 z-10">
        <SeeInActionVideo_Component />
      </section>

      {/* Render LimitlessPossibilitiesSection_Component */}
      <section className="my-20">
        <LimitlessPossibilitiesSection_Component />
      </section>

      <CaseStudyVideo_Component />

      {/* Render CaseStudy_Component */}
      <section className="my-20" id="case-study">
        <CaseStudy_Component />
      </section>

      {/* Render SignUpForm_Component */}
      <SignUpForm_Component />
    </>
  );
}
