// ["LimitlessPossibilitiesSection", "Component"]

// react imports go here
import React from "react";

// Import BackgroundImageNeonVectors
import BackgroundImageNeonVectors from "./BackgroundImageNeonVectors_Image.webp";

// Default export of LimitlessPossibilitiesSection_Component
export default function Legal_Component() {
  // IMPORTANT: This component serves as a placeholder and does not interact with the context state or functionality.
  return (
    <div className="text-4xl font-bold tracking-tight text-white sm:text-6xl">
      {/* Background image */}
      <div className="w-full bg-transparent relative pt-32 pb-32">
        <div
          className="absolute inset-0 w-full h-full z-0 sm:h-[90vh] xs:h-[200vh]"
          style={{
            backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 0) 80%, rgba(0, 0, 0, 0.8) 100%), url(${BackgroundImageNeonVectors})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            opacity: 0.5,
          }}
          aria-label="Bright neon vector background with abstract shapes and patterns"
        />

        {/* ================= PRIVACY POLICY ================= */}
        <div className="flex flex-col items-center justify-center text-white z-10 relative">
          <h2 className="text-[60px] font-normal tracking-[1.2px] font-bold text-white">
            Privacy Policy
          </h2>
          <p className="text-[30px] tracking-normal font-normal text-white my-4">
            Last updated: 7 Jan 2025
          </p>
          <div className="w-3/4 text-[20px] tracking-normal font-light text-white my-4 space-y-6">
            <p>
              This Privacy Policy describes how Origin AI inc (“Origin AI,”
              “we,” “us,” or “our”) collects, uses, discloses, and safeguards
              your personal data when you use our platform (the “Platform”). By
              using our Platform, you agree to the practices described in this
              Privacy Policy.
            </p>

            {/* 1. Scope and Overview */}
            <h3 className="font-bold text-[24px] mt-8">
              1. Scope and Overview
            </h3>
            <p>
              This Privacy Policy applies to the personal data we collect
              through our Platform and related services. It does not cover
              third-party websites, applications, or services that we do not
              control.
            </p>

            {/* 2. Data Collection */}
            <h3 className="font-bold text-[24px] mt-8">2. Data Collection</h3>
            <p className="mt-2">
              <strong>Types of Data Collected:</strong> We may collect the
              following personal data from you:
            </p>
            <ul className="list-disc ml-5">
              <li>
                Contact Information: Name, email address, and employer name and
                details
              </li>
              <li>Technical Data: IP address, cookies, and usage logs</li>
              <li>
                Payment Information: Payment credentials and related transaction
                data
              </li>
            </ul>
            <p>
              We do not intentionally collect sensitive personal data (e.g.,
              race, health, biometrics).
            </p>
            <p className="mt-2">
              <strong>Methods of Collection:</strong>
            </p>
            <ul className="list-disc ml-5">
              <li>Forms you complete on the Platform</li>
              <li>Cookies and similar technologies</li>
              <li>Usage logs and analytics tools</li>
            </ul>
            <p className="mt-2">
              <strong>Third-Party Deployments:</strong> Our Platform may enable
              you to create and deploy applications. We do not assume
              responsibility for the privacy practices of these deployed
              applications. You, as the creator, are responsible for
              establishing and maintaining appropriate privacy policies for your
              deployed applications.
            </p>

            {/* 3. Data Use */}
            <h3 className="font-bold text-[24px] mt-8">3. Data Use</h3>
            <p>We use the personal data we collect for:</p>
            <ul className="list-disc ml-5">
              <li>Account creation, authentication, and user support</li>
              <li>Improving and refining our Platform and AI models</li>
              <li>
                Analytics, to understand Platform usage and improve user
                experience
              </li>
              <li>Security and fraud prevention</li>
              <li>Generating and facilitating user-created applications</li>
            </ul>
            <p>
              We may use user-provided data to train or fine-tune our underlying
              Generative AI models. Users should not input sensitive data into
              the Platform.
            </p>

            {/* 4. Data Sharing */}
            <h3 className="font-bold text-[24px] mt-8">4. Data Sharing</h3>
            <p>We may share your data with:</p>
            <ul className="list-disc ml-5">
              <li>
                Service providers such as cloud storage, analytics providers,
                and infrastructure providers
              </li>
              <li>Third-party AI providers integrated into the Platform</li>
              <li>Law enforcement or government agencies as required by law</li>
            </ul>
            <p>
              We use reputable providers (e.g., AWS, analytics tools) for
              hosting and analytics.
            </p>

            {/* 5. Data Storage and Security */}
            <h3 className="font-bold text-[24px] mt-8">
              5. Data Storage and Security
            </h3>
            <p>
              <strong>Location:</strong> Your data is stored in data centers
              located in Germany (EU).
            </p>
            <p>
              <strong>Retention:</strong> We retain personal data for as long as
              you maintain an active subscription or account.
            </p>
            <p>
              <strong>Security Measures:</strong> We use HTTPS for data in
              transit and AWS Cognito for identity services. Data is not
              currently encrypted at rest or anonymized.
            </p>
            <p>
              Although we take reasonable steps to protect your data, no
              security measures are infallible.
            </p>

            {/* 6. User Rights */}
            <h3 className="font-bold text-[24px] mt-8">6. User Rights</h3>
            <p>
              You have the right to access, correct, or delete your personal
              data. To exercise these rights, please contact us at:
            </p>
            <p>
              Email:{" "}
              <a
                href="mailto:support@theorigin.ai"
                className="text-blue-300 hover:underline"
              >
                support@theorigin.ai
              </a>
            </p>
            <p>
              We do not distinguish between different regional privacy rights
              and generally comply with user requests to the extent feasible and
              legally required.
            </p>

            {/* 7. Use of AI and Generated Content */}
            <h3 className="font-bold text-[24px] mt-8">
              7. Use of AI and Generated Content
            </h3>
            <p>
              Our Generative AI system may store and process user prompts and
              generated outputs.
            </p>
            <p>
              <strong>Ownership of Generated Applications:</strong> You own the
              intellectual property rights to the applications you create.
            </p>
            <p>
              <strong>Liability for Generated Content:</strong> You are
              responsible for ensuring that generated applications do not
              infringe upon third-party rights or create harmful content. Origin
              AI assumes no liability for errors, biases, or inappropriate
              content produced by third-party AI models.
            </p>

            {/* 8. Cookies and Tracking Technologies */}
            <h3 className="font-bold text-[24px] mt-8">
              8. Cookies and Tracking Technologies
            </h3>
            <p>
              We use cookies and similar technologies for essential Platform
              functions and analytics. We do not offer a cookie management tool
              or opt-out mechanism at this time. By using the Platform, you
              consent to the use of these technologies.
            </p>

            {/* 9. Compliance */}
            <h3 className="font-bold text-[24px] mt-8">9. Compliance</h3>
            <p>
              We aim to comply with applicable European data protection laws,
              including the EU General Data Protection Regulation (GDPR). We
              currently do not employ specific measures for detecting or
              responding to data breaches.
            </p>

            {/* 10. User Responsibilities */}
            <h3 className="font-bold text-[24px] mt-8">
              10. User Responsibilities
            </h3>
            <p>
              Users should avoid inputting sensitive or legally restricted data.
              While we do not impose explicit responsibilities on users, we
              recommend that you comply with any legal obligations you may have
              regarding the data you enter into or process through the Platform.
            </p>

            {/* 11. International Considerations */}
            <h3 className="font-bold text-[24px] mt-8">
              11. International Considerations
            </h3>
            <p>
              The Platform is available to users globally, but data is stored
              and processed in the EU. If you access the Platform from outside
              the EU, you do so at your own initiative and are responsible for
              compliance with local laws.
            </p>

            {/* 12. Platform Usage Analytics */}
            <h3 className="font-bold text-[24px] mt-8">
              12. Platform Usage Analytics
            </h3>
            <p>
              We collect usage information (e.g., features used, time spent)
              linked to individual user accounts for analytics and to improve
              the Platform.
            </p>

            {/* 13. Changes to this Privacy Policy */}
            <h3 className="font-bold text-[24px] mt-8">
              13. Changes to this Privacy Policy
            </h3>
            <p>
              We may update this Privacy Policy from time to time. When we make
              changes, we will notify you by email. Your continued use of the
              Platform after any changes constitute your acceptance of the
              revised Privacy Policy.
            </p>

            {/* 14. Contact Information */}
            <h3 className="font-bold text-[24px] mt-8">
              14. Contact Information
            </h3>
            <p>
              If you have questions or requests regarding your personal data or
              this Privacy Policy, please contact us at:
            </p>
            <address className="not-italic">
              Origin AI inc
              <br />
              1007 N Orange St. 4th Floor Ste 1382
              <br />
              Wilmington, 19801
              <br />
              Email:{" "}
              <a
                href="mailto:support@theorigin.ai"
                className="text-blue-300 hover:underline"
              >
                support@theorigin.ai
              </a>
            </address>
          </div>
        </div>

        {/* ================= USER AGREEMENT ================= */}
        <div className="flex flex-col items-center justify-center text-white z-10 relative mt-16">
          <h2 className="text-[60px] font-normal tracking-[1.2px] font-bold text-white">
            User Agreement
          </h2>
          <p className="text-[30px] tracking-normal font-normal text-white my-4">
            Last updated: 7 Jan 2025
          </p>
          <div className="w-3/4 text-[20px] tracking-normal font-light text-white my-4 space-y-6">
            <p>
              This User Agreement (“Agreement”) governs your access to and use
              of the platform and services provided by Origin AI inc (“Origin
              AI,” “we,” “us,” or “our”). By accessing or using our website and
              associated services (the “Platform”), you agree to be bound by the
              terms and conditions set forth in this Agreement. If you do not
              agree, you must discontinue use of the Platform.
            </p>

            {/* 1. Eligibility */}
            <h3 className="font-bold text-[24px] mt-8">1. Eligibility</h3>
            <p>
              <strong>1.1 Age Requirement:</strong> You must be at least 18
              years old to use the Platform. By using the Platform, you
              represent and warrant that you meet this age requirement.
            </p>

            {/* 2. Use of the Platform */}
            <h3 className="font-bold text-[24px] mt-8">
              2. Use of the Platform
            </h3>
            <p>
              <strong>2.1 Beta Status:</strong> The Platform is currently
              provided on a beta basis. We do not charge fees for its use at
              this time.
            </p>
            <p>
              <strong>2.2 Compliance with Laws:</strong> You agree to use the
              Platform in compliance with all applicable laws, regulations, and
              industry standards. You are solely responsible for ensuring that
              any content, applications, or outputs you generate or deploy
              comply with applicable legal requirements.
            </p>
            <p>
              <strong>2.3 Prohibited Conduct:</strong> You agree not to use the
              Platform to upload, create, or distribute any content that is
              unlawful, harmful, fraudulent, infringing, or otherwise
              objectionable. You further agree not to use the Platform to
              circumvent security measures, disrupt the Platform’s operation, or
              obtain unauthorized access to systems or data.
            </p>

            {/* 3. Intellectual Property Rights */}
            <h3 className="font-bold text-[24px] mt-8">
              3. Intellectual Property Rights
            </h3>
            <p>
              <strong>3.1 Platform IP:</strong> All intellectual property rights
              in the Platform and our proprietary content, software, and
              materials belong to Origin AI or our licensors. Except as
              expressly permitted, you must not copy, reproduce, distribute, or
              create derivative works from the Platform’s content without our
              prior written consent.
            </p>
            <p>
              <strong>3.2 User-Generated Content:</strong> Any code,
              applications, designs, outputs, or other intellectual property
              that you create using the Platform (“User-Generated Content”) is
              owned solely by you. However, you acknowledge that the Platform’s
              underlying models and tools remain owned by Origin AI or its
              licensors.
            </p>
            <p>
              <strong>3.3 Public Sharing:</strong> You may publicly share or
              publish applications and other User-Generated Content created
              using the Platform, subject to your compliance with this Agreement
              and applicable laws.
            </p>

            {/* 4. Liability for Generated Applications */}
            <h3 className="font-bold text-[24px] mt-8">
              4. Liability for Generated Applications
            </h3>
            <p>
              <strong>4.1 User Responsibility:</strong> You are solely
              responsible for any applications, content, or outputs you create,
              use, or deploy using the Platform, including their compliance with
              applicable laws and regulations, their security, and any potential
              harm they may cause to users or third parties.
            </p>
            <p>
              <strong>4.2 No Liability for Origin AI:</strong> Origin AI assumes
              no responsibility or liability for applications or content you
              create or deploy using the Platform. This includes, without
              limitation, any damages, losses, security breaches, dangerous
              functionalities, or other harm arising from or related to your
              User-Generated Content.
            </p>

            {/* 5. Disclaimers and Warranties */}
            <h3 className="font-bold text-[24px] mt-8">
              5. Disclaimers and Warranties
            </h3>
            <p>
              <strong>5.1 Beta Disclaimer:</strong> The Platform is provided on
              an “as is” and “as available” basis without any warranties,
              express or implied. We do not guarantee the accuracy, reliability,
              or performance of the Platform or any generated outputs.
            </p>
            <p>
              <strong>5.2 No Warranty:</strong> To the fullest extent permitted
              by law, we disclaim all warranties, including fitness for a
              particular purpose, merchantability, non-infringement, and
              uninterrupted or error-free operation.
            </p>
            <p>
              <strong>5.3 Third-Party Models and Tools:</strong> The Platform
              may incorporate or utilize third-party AI models, infrastructure,
              or services. We do not control these third parties and disclaim
              any liability for their content, functionality, or practices.
            </p>

            {/* 6. Limitation of Liability */}
            <h3 className="font-bold text-[24px] mt-8">
              6. Limitation of Liability
            </h3>
            <p>
              <strong>6.1 Exclusion of Damages:</strong> To the fullest extent
              permitted by law, Origin AI will not be liable for any indirect,
              incidental, special, consequential, or exemplary damages arising
              from or related to your use of the Platform or any User-Generated
              Content.
            </p>
            <p>
              <strong>6.2 Limitation of Direct Damages:</strong> To the fullest
              extent permitted by law, our total liability for any claims
              related to this Agreement or the Platform will not exceed the
              amount you paid to us for use of the Platform in the twelve (12)
              months preceding the claim (or USD $0 if no fees have been paid).
            </p>

            {/* 7. Indemnification */}
            <h3 className="font-bold text-[24px] mt-8">7. Indemnification</h3>
            <p>
              You agree to indemnify and hold Origin AI harmless from any
              claims, damages, losses, liabilities, costs, and expenses
              (including attorneys’ fees) arising out of or related to (a) your
              use of the Platform, (b) your User-Generated Content, (c) your
              violation of any laws, or (d) your breach of this Agreement.
            </p>

            {/* 8. Governing Law */}
            <h3 className="font-bold text-[24px] mt-8">8. Governing Law</h3>
            <p>
              This Agreement will be governed by and construed in accordance
              with the laws of the State of Delaware, without regard to its
              conflict of law principles. If any dispute arises between you and
              Origin AI related to this Agreement or the Platform, the courts
              located in Delaware shall have exclusive jurisdiction.
            </p>

            {/* 9. Changes to this Agreement */}
            <h3 className="font-bold text-[24px] mt-8">
              9. Changes to this Agreement
            </h3>
            <p>
              We may update or modify this Agreement from time to time. When we
              make changes, we will notify users by email or by posting a notice
              on the Platform. Your continued use of the Platform after any
              changes become effective constitutes acceptance of the revised
              Agreement.
            </p>

            {/* 10. Termination */}
            <h3 className="font-bold text-[24px] mt-8">10. Termination</h3>
            <p>
              We may terminate or suspend your access to the Platform at any
              time, with or without cause, and without notice or liability.
              Sections 3, 4, 5, 6, 7, 8, and any provisions intended to survive
              termination shall survive.
            </p>

            {/* 11. Entire Agreement */}
            <h3 className="font-bold text-[24px] mt-8">11. Entire Agreement</h3>
            <p>
              This Agreement constitutes the entire agreement between you and
              Origin AI concerning the use of the Platform and supersedes all
              prior agreements, written or oral.
            </p>

            {/* 12. Contact Us */}
            <h3 className="font-bold text-[24px] mt-8">12. Contact Us</h3>
            <p>
              If you have any questions about this Agreement, please contact us
              at:
            </p>
            <address className="not-italic">
              Origin AI inc
              <br />
              1007 N Orange St. 4th Floor Ste 1382
              <br />
              Wilmington, 19801
              <br />
              Email:{" "}
              <a
                href="mailto:support@theorigin.ai"
                className="text-blue-300 hover:underline"
              >
                support@theorigin.ai
              </a>
            </address>
          </div>
        </div>
      </div>
    </div>
  );
}
