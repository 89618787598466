// ["HeadingSection", "Component"]
// This guide file contains a number of hero section examples
// You must choose only one based on the specification
// You should complete any text as required based on context rather than leaving the text content as it is in the example

// IMPORTANT: This component is designed to be placed at the top of the landing page

import React from "react";

export default function HeadingSection_Component() {
  // Function to handle 'Get an invite' button click
  const handleInviteClick = (elementId) => {
    // Scroll to the SignUpForm component
    const signUpForm = document.getElementById(elementId);
    if (signUpForm) {
      signUpForm.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div>
      {/* Heading section with centered text and transparent background */}
      <div className="bg-transparent px-6 py-24 sm:py-32 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-[60px] not-italic font-normal leading-[72px] tracking-[1.2px] text-4xl font-bold tracking-tight text-white sm:text-6xl">
            Generate and deploy software with the world's first AI product team
          </h2>

          <div className="mt-8 flex justify-center space-x-4">
            {/* 'See it in action' button */}
            <button
              type="submit"
              onClick={() => handleInviteClick("demo")}
              className="w-30 flex items-center justify-center rounded-md border border-transparent bg-[#363638] py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              aria-label="Jump to signup see it in action"
            >
              See it in action
            </button>
            {/* 'Get started' button */}
            <button
              type="submit"
              onClick={() => window.open("https://alpha.theorigin.ai")}
              className="w-30 flex items-center justify-center rounded-md border border-transparent bg-[#363638] py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              aria-label="Jump to signup get started"
            >
              Get started
            </button>
            <div
              dangerouslySetInnerHTML={{
                __html: `
      <a href="https://www.producthunt.com/posts/origin-6?embed=true&utm_source=badge-featured&utm_medium=badge&utm_souce=badge-origin-6" target="_blank" rel="noopener noreferrer">
        <img
          src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=806201&theme=light&t=1737617910955"
          alt="Origin - The world's first AI product team | Product Hunt"
          style="width: 250px; height: 54px;"
          width="250"
          height="54"
        />
      </a>
    `,
              }}
            />
          </div>
        </div>
      </div>
      {/* Existing SignUp form should be placed directly below this heading section */}
    </div>
  );
}
